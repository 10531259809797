<template>
  <div class="notifications">
    <LazyVNotificationCard
      v-for="(card, index) in notifArr"
      :key="JSON.stringify(card) + index"
      :card="card"
      :style="'z-index:' + index + 1"
    />
  </div>
</template>

<script setup lang="ts">
import { useTariffs } from '~/store/tariffs/tariffs.store'

const tariffsStore = useTariffs()
const notifArr = computed(() => tariffsStore.getNotifArr)
</script>

<style scoped lang="scss">
.notifications {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 2500;
}
</style>
